import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Rute } from "../config";

function App() {
  return <Rute />;
}

export default App;
